export const ROUTE_PATHS = {
	MARKETING: '/',
	TERMS_OF_SERVICE: '/terms',
	PRIVACY_POLICY: '/privacy',
	MARKETPLACE: {
		INDEX: '/marketplace',
		CATEGORIES: '/marketplace/categories',
		PRODUCTS: '/marketplace/products',
		SUPPLIERS: '/marketplace/suppliers',
		CHECKOUT: '/marketplace/checkout',
	},
	DASHBOARD: {
		HOME: {
			BASE: '/dashboard/home',
			ACCOUNT_SETUP: {
				BASE: '/dashboard/home/account-setup',
				DOCV: '/dashboard/home/account-setup/document-verification',
				TOC: '/dashboard/home/account-setup/terms-and-conditions',
				COMPLETE: '/dashboard/home/account-setup/complete',
				FINISH: '/dashboard/home/account-setup/finish',
			},
			MESSAGING: '/dashboard/messaging',
		},
		ACCOUNT: {
			BASE: '/dashboard/account',
		},
		PAYMENTS: {
			INDEX: '/dashboard/payments',
			ROUTEFUSION: '/dashboard/payments/routefusion',
			STRIPE: '/dashboard/payments/stripe',
			PAYPAL: '/dashboard/payments/paypal',
		},
		ITEMS: {
			CREATE: '/dashboard/items/create',
			INVENTORY: '/dashboard/items/inventory',
			// PRICING: '/dashboard/items/pricing',
		},
		FULFILLMENT: {
			ORDERS: '/dashboard/fulfillment/orders',
			// RETURNS: '/dashboard/fulfillment/returns',
		},
		DATA: {
			OVERVIEW: '/dashboard/data/overview',
			// DATA: '/dashboard/data/deep-dive',
		},
		ORGANIZATION: {
			ADMIN: {
				INDEX: '/dashboard/organization/admin',
				START: '/dashboard/organization/admin/start',
				KYB: '/dashboard/organization/admin/kyb',
				PAGE: '/dashboard/organization/admin/page',
				COMPLETE: '/dashboard/organization/admin/complete',
			},
			INFO: '/dashboard/organization/info',
			BANKING: '/dashboard/organization/banking',
			SHIPPING: '/dashboard/organization/shipping',
		},
		LENDING: {
			STATUS: '/dashboard/lending/status',
			APPLY: '/dashboard/lending/apply',
		},
		ADMIN: {
			ROLES: '/dashboard/admin/roles',
			INVITATIONS: '/dashboard/admin/invitations',
		},
	},
	COMPANY: {
		INDEX: '/company',
	},
	ACCOUNT: {
		DASHBOARD: '/account/dashboard',
		MESSAGING: '/account/dashboard',
	},
	AUTH: {
		LOGIN: '/login',
		VERIFY: '/verify',
	},
}

export const getCompanyStorefrontUrl = (orgId: string, name: string) =>
	ROUTE_PATHS.COMPANY.INDEX + `/${name?.replaceAll(' ', '-')}?orgID=${orgId}`
